import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Container from "../../../../common/components/UI/Container";
import { PROBLEMS_WE_SOLVE_DATA } from "../../../../common/data/SecD3v";
import React, { useState } from "react";
import KeyBenefitsArea from "./section.style";

const SectionArea = () => {
  const { blockTitle, post } = PROBLEMS_WE_SOLVE_DATA;
  const { MainTitle, title } = blockTitle;

  const [open, setOpen] = useState(false);
  const onOpenModal = () => functionA();
  const onCloseModal = () => setOpen(false);

  const [benefitValue, setBenefitValue] = useState("");

  const retrieveBenefits = (title, description) => {
    setBenefitValue({ title, description });
    setOpen(true);
  };

  return (
    <KeyBenefitsArea id="problems-we-solve">
      <Container>
        <Box className="blockTitle">
          <Heading as="h2" content={MainTitle} />
          {/* <Text as="p" content={text} /> */}
        </Box>
        <Box className="grid-container">
          {post?.map(({ icon, title, description }, index) => (
            <div class="grid-item">
              <Box className="post" key={`feature-post-key-${index}`}>
                <div className="feature-image-box">
                  <div className="feature-image-box-inner">
                    <img className="benefits-icon" src={icon} alt={title} />
                  </div>
                </div>
                <strong>
                  <Heading as="h3" content={title} />
                </strong>
                <div className="feature-text-box">{description}</div>
              </Box>
            </div>
          ))}
        </Box>
      </Container>
    </KeyBenefitsArea>
  );
};

export default SectionArea;
